import React, { useState, useEffect, useRef } from "react";
import {
  Skeleton,
  Row,
  Col,
  Typography,
} from "antd";
import { connect } from "react-redux";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import config from "../../config";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  TopContainer,
} from "../BookingDetails/BookingDetailsStyle";
import {
  getAppointmentDetails,
  getAppointmentDetailsReset,
} from "../../action/getAppointmentDetailsAction";
import {
  bookedAppointmentActivity,
  bookedAppointmentReset,
} from "../../action/bookingAppointmentActivity";
import { convertTZ, dateTimeUTCFormat, timeSlotFormat } from "../../utils";
import './AppointmentMeeting.css';

const { Title } = Typography;

const AppointmentMeeting = (props) => {
  const {
    getAppointmentDetails,
    getAppointmentDetailsReset,
    getAppointmentDetailsState,
    bookedAppointmentActivity,
    bookingAppointmentActivityState,
    bookedAppointmentReset,
  } = props;

  const id = props.match.params.id;
  const [onCall, setOnCall] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const sessionContainerRef = useRef(null);
  let history = useHistory();

  const zoomConfig = {
    videoSDKJWT: "",
    sessionName: id, // Using appointment ID as session name
    userName: getAppointmentDetailsState?.data?.patient_id?.name?.firstName || "Patient",
    sessionPasscode: "",
    features: ["video", "audio", "settings", "users", "chat","share"],
    options: { init: {}, audio: {}, video: {}, share: {} }
  };

  console.log("zoomConfig", zoomConfig);

  const [isZoomInitialized, setIsZoomInitialized] = useState(false);

  const joinZoomSession = async () => {
    if (isZoomInitialized) return;
    
    try {
      const response = await fetch(`${config.api.base_url}zoom-sdk/get-zoom-sdk-auth-token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          sessionName: zoomConfig.sessionName,
          role: 1
        }),
      });
      
      const data = await response.json();
      if (data.data.signature) {
        console.log("data.data.signature", data.data.signature);
        zoomConfig.videoSDKJWT = data.data.signature;
        uitoolkit.joinSession(sessionContainerRef.current, zoomConfig);
        setIsZoomInitialized(true);
        
        uitoolkit.onSessionClosed(() => {
          
          bookedAppointmentActivity({
            book_appointment_id: id,
            activity_type: "disconnected",
          });
          history.push({
            pathname: "/appointment-details",
            state: {
              Details: {
                item: getAppointmentDetailsState.data,
              },
              isBooking: true,
              doctor: `${getAppointmentDetailsState.data.doctor_id._id}`,
              app: "upcom",
            },
          });
        });
      }
    } catch (error) {
      console.error("Failed to join Zoom session:", error);
    }
  };

  useEffect(() => {
    return () => {
      if (isZoomInitialized) {
        setIsZoomInitialized(false);
        uitoolkit.leaveSession();
        
      }
    };
  }, [isZoomInitialized]);

  useEffect(() => {
    if (onCall && 
        getAppointmentDetailsState.data?.is_can_join_room && 
        !isZoomInitialized && 
        sessionContainerRef.current) {
      joinZoomSession();
    }
  }, [onCall, getAppointmentDetailsState.data?.is_can_join_room, isZoomInitialized]);

  useEffect(() => {
    return () => {
      getAppointmentDetailsReset();
    };
  }, []);

  useEffect(() => {
    if (bookingAppointmentActivityState.apiState) {
      bookedAppointmentReset();
    }
  }, [bookingAppointmentActivityState]);

  useEffect(() => {
    if (getAppointmentDetailsState.apiState === "success") {
      let date = moment(getAppointmentDetailsState.data.date).utc().unix();
      let to_date = moment(getAppointmentDetailsState.data.to_date)
        .utc()
        .unix();
      // let current_date = moment(new Date()).add(-2, 'minute').unix()
      let current_date = convertTZ(
        moment().add(-10, "second").format("YYYY-MM-DD HH:mm:ss") + " +0000",
        "Asia/Kolkata"
      );
      let current_date_15_min_after = convertTZ(
        moment().add(15, "minutes").format("YYYY-MM-DD HH:mm:ss") + " +0000",
        "Asia/Kolkata"
      );
      current_date = moment(current_date).utc().unix();
      current_date_15_min_after = moment(current_date_15_min_after).utc().unix();
      if (date <= current_date_15_min_after && to_date >= current_date) {
        setOnCall(true);
        bookedAppointmentActivity({
          book_appointment_id: id,
          activity_type: "joined",
        });
      } else {
        setInterval(() => setRefresh(refresh + 1), 5000);
      }
    }
  }, [getAppointmentDetailsState, refresh]);

  useEffect(() => {
    if (id) {
      getAppointmentDetails({
        id: id,
      });
    }
  }, [id]);

  return (
    <>
      <div className="container" style={{ marginTop: "10%", marginBottom: "5%" }}>
        <TopContainer>
          <img
            className="align-center mobile-height"
            src="./images/lilac-insights-logo.svg"
            alt="exp"
          />
        </TopContainer>

        <Row gutter={22}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            {getAppointmentDetailsState.apiState === "loading" && (
              <Skeleton active />
            )}

            {getAppointmentDetailsState.apiState === "error" && (
              <p>{getAppointmentDetailsState.message}</p>
            )}
            
            {getAppointmentDetailsState.apiState === "success" && (
              <>
                {!onCall && !getAppointmentDetailsState.data.is_can_join_room && (
                  <Row gutter={20}>
                    <Col span="12">
                      Your session will start on{" "}
                      {dateTimeUTCFormat(getAppointmentDetailsState.data.date)}
                    </Col>
                  </Row>
                )}
                
                {onCall && getAppointmentDetailsState.data.is_can_join_room && (
                  <div className="zoom-container" ref={sessionContainerRef} />
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getAppointmentDetailsState: state.getAppointmentDetails,
  bookingAppointmentActivityState: state.bookingAppointmentActivityState,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointmentDetails: (params) => dispatch(getAppointmentDetails(params)),
  getAppointmentDetailsReset: () => dispatch(getAppointmentDetailsReset()),
  bookedAppointmentActivity: (params) => dispatch(bookedAppointmentActivity(params)),
  bookedAppointmentReset: () => dispatch(bookedAppointmentReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentMeeting);
